 /* eslint-disable */ 
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { CSVLink } from "react-csv";
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { deleteForm } from '../../../stores/actions/form';
import BootStrapDialog from '../../../components/Dialog'
// component
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------

export default function UserMoreMenu({ records, id, title, observer }) {

  // console.log(records)

  //Export checklist
  const exportSpecies = records.map((bird) => {
    return {
      observer: bird.observer,
      title: bird.title,
      ['Species Name']: bird.englishName,
      Date: moment(bird.createdAt).format('L'),
      Time: moment(bird.createdAt).format('LT'),
      ['Seen/Heard']: bird.seenOrHeard,
      Latitude: bird.latitude,
      Longitude: bird.longitude,
      pentad: bird.pentad,
      Count: bird.count,
      ['Count is']: bird.countIs,
      Habitat: "Unspecified",
      // Fieldsheet: `${bird.pentad}_${moment(bird.createdAt).format('MM-DD-YYYY')}`,
      ['ISO Date']: bird.createdAt
  
    }
  })


  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete =() => {
    dispatch(deleteForm(id))
  }

  const [ebirdExport, setEbirdExport] = useState({
    location: '',
    country: '',
    province: '',
    distance: '',
    area: '',
    comments: '',
  })


  const speciesComments = "Species Comments"
  const commonName = "Common Name"
  const locationName = "Location Name"
  const startTime = "Start Time"
  const province = "State/Province"
  const country = "Country Code"
  const numberOfObservers = "Number of Observers"
  const allObserved = "All observations reported?"
  const distance = "Effort Distance Miles"
  const area = "Effort area acres"
  const comments = "Submission Comments"

const exportToEbird = records.map((record) => {
  return{
    [`${commonName}`]: record.englishName, 
    Genus: '',
    Species: '',
    Number: record.count,
    [`${speciesComments}`]: '',
    [`${locationName}`]: ebirdExport.location,
    Latitude: record.latitude, 
    Longitude: record.longitude,
    Date: record.date,
    [`${startTime}`]: record.time,
    [`${province}`]: ebirdExport.province,
    [`${country}`]: ebirdExport.country,
    Protocol: 'Traveling',
    [`${numberOfObservers}`]: 1,
    Duration: 60,
    [`${allObserved}`]: "Y",
    [`${distance}`]: ebirdExport.distance,
    [`${area}`]: ebirdExport.area,
    [`${comments}`]: ebirdExport.comments,


  }
})


  
  const exportToBirdLasser = records.map((record) => {
    return {
      SPECIES_NAME_ENG: record.englishName ? record.englishName : record.commonName, 
      TRIPCARD: record.title, 
      LATITUDE: record.latitude, 
      LONGITUDE: record.longitude, 
      DATE_TIME: `${record.date}T${record.time}`,
      TIMEZONE: 120,
      SEEN: true,
      LIFER: true,
      COUNT: record.count
      }
  })


  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
    <BootStrapDialog 
        open={open} 
        setOpen={setOpen} 
        ebirdExport={ebirdExport} 
        setEbirdExport={setEbirdExport} 
        handleClose={handleClose} 
        exportToEbird={exportToEbird}
      />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >

    <CSVLink
        data={exportSpecies}
        filename={"Checklist.csv"}
        className="btn btn-primary"
        target="_blank"
        style={{textDecoration: 'none'}}
      >
        <MenuItem component={'button'} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:download-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Download Checklist" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
    </CSVLink>

    {/* <CSVLink
        data={species}
        filename={"datasheet.csv"}
        className="btn btn-primary"
        target="_blank"
        style={{textDecoration: 'none'}}
      >
        <MenuItem component={'button'} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:download-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Download Checklist" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
    </CSVLink> */}

        {/* <CSVLink
        data={exportToBirdLasser}
        filename={"tripcard.csv"}
        className="btn btn-primary"
        target="_blank"
        style={{textDecoration: 'none'}}
      >
        <MenuItem component={'button'} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:download-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Export→BirdLasser" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        </CSVLink> */}


        <MenuItem component={'button'} sx={{ color: 'text.secondary' }} onClick={handleClickOpen}>
          <ListItemIcon>
            <Iconify icon="eva:download-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Export→eBird" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        

        {/* <MenuItem component={'button'} sx={{ color: 'text.secondary' }} onClick={handleDelete}>
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

        {/* <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

      </Menu>
    </>
  );
}
