import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import ViewData from './pages/ViewData';
import Species from './pages/Species';
import Privacy from './pages/Privacy';
import PostDetail from './pages/PostDetail';
import Forms from './pages/Forms';
import Checklists from './pages/Checklists';
import Importer from './pages/Importer';
import Observers from './pages/Observers';
import Login from './pages/Login';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import Posts from './pages/Posts';
import DashboardApp from './pages/DashboardApp';
import DataForms from './pages/DataForms';
import AllForms from './pages/AllForms';
import Home from './pages/Home';
import Create from './pages/Create';
import Edit from './pages/Edit';
import About from './pages/About';
import Contact from './pages/Contact';
import Docs from './pages/Docs';

// ----------------------------------------------------------------------

export default function Router() {


  const user = JSON.parse(localStorage.getItem('profile'))


  return useRoutes([
    {
      path: user ? '/dashboard' : '/',
      element: user ? <DashboardLayout /> : <Home />,
      children: [
        // { path: 'app', element: <DataForms /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'atlasing', element: <DashboardApp /> },
        { path: 'forms', element: <Forms /> },
        { path: 'allforms', element: <AllForms /> },
        { path: 'checklists', element: <Checklists /> },
        { path: 'observers', element: <Observers /> },
        { path: 'importer', element: <Importer /> },
        { path: 'products', element: <Products /> },
        { path: 'posts', element: <Posts/> },
        { path: 'write', element: <Create /> },
        { path: 'edit/:slug', element: <Edit /> },
        // { path: 'news', element: <Blog /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'news', element: <Blog /> },
        { path: 'docs', element: <Docs /> },
        { path: 'privacy', element: <Privacy /> },
        { path: 'viewData', element: <ViewData /> },
        { path: 'species', element: <Species /> },
        { path: 'about', element: <About /> },
        { path: 'contact', element: <Contact /> },
        { path: 'news/:slug', element: <PostDetail /> },
        { path: 'register', element: <Register /> },
        { path: 'resetpassword', element: <ResetPassword /> },
        { path: 'newpassword/:token', element: <NewPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
