/* eslint-disable */
import React, { useEffect, useState } from 'react'
import customStyles from './dash.module.css'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'underscore'
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button } from '@mui/material';
// components
import Page from '../components/Page';
import SelectForm from '../components/SelectForm';
import Home from './Home'
import Progress from '../components/Progress'
// import Iconify from '../components/Iconify';
// sections
import {
  // AppTasks,
  // AppNewsUpdate,
  // AppOrderTimeline,
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppWidgetSummary,
} from '../sections/@dashboard/app';


import { getFormsByUser} from '../stores/actions/form'
import FullScreenDialog from '../components/FullScreenDialog/FullScreenDialog';

// ----------------------------------------------------------------------

import { getBirdsByUser } from '../stores/actions/birds';

import Checklists from './Checklists';
import FirebaseForms from './FirebaseForms';


export default function DashboardApp() {


  const user = JSON.parse(localStorage.getItem('profile'))
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)


  const navigate = useNavigate()
  const theme = useTheme();
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const { forms } = useSelector((state) => state.forms)
  const { birds, summary } = useSelector((state) => state.birds)
  const [selectedForms, setSelectedForms] = React.useState([]);

  // const activeList = selectedForms.length > 0 ? selectedForms : forms
  const activeList = birds ? birds : undefined


useEffect(() => {
  dispatch(getBirdsByUser({search: user?.result?._id}))
}, [])

// console.log("Summary", summary)



  const checkList = activeList.map((list) => {
    return {
      id: list?.checkList,
      title: list?.listDetail?.title,
      observer: list?.listDetail?.observer,
      records: list?.listDetail?.species,
      species: _.uniq(list?.listDetail?.species, 'scientificName'),
      avatarUrl: "/static/mock-images/avatars/avatar_default.jpg"
    }
  })


// useEffect(() => {
//   dispatch(getFormsByUser(
//     { search: user?.result?.isOrganization ? 
//       user?.result?.organizationCode 
//       : 
//       user?.result?._id 
//     }
//     ))
// },[])




const [open, setOpen] = React.useState(false);
const handleClickOpen = () => {
  setOpen(true);
};

const openRecords =() => {
  navigate('/dashboard/checklists')
}


  return (
    <>
   {activeList === undefined ? 
   ( <Progress />): 
  (
    <>
    {/* <FullScreenDialog handleClickOpen={handleClickOpen} setOpen={setOpen} open={open} uniqueSpecies={uniqueSpecies} /> */}
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 3 }}>
        BirdMap Data
        </Typography>

        {/* <SelectForm forms={forms} selectedForms={selectedForms} setSelectedForms={setSelectedForms} /> */}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} onClick={openRecords}>
            <AppWidgetSummary title="Records" total={summary?.totalRecords} icon={'ant-design:pie-chart-filled'} />
          </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className={customStyles.statCard} onClick={handleClickOpen} >
              <AppWidgetSummary title="Species" total={summary?.uniqueSpecies} color="info" icon={'ant-design:yuque-filled'} />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} onClick={openRecords} >
            <AppWidgetSummary title={checkList.length > 1 ? "Checklists" : "Checklist"} total={checkList.length} color="warning" icon={'ant-design:fund-filled'} />
          </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
          <div className={customStyles.statCard} >
            <AppWidgetSummary title={summary?.pentads?.length > 1 ? "Pentads" : "Pentad"} total={summary?.pentads?.length} color="error" icon={'ant-design:contacts-filled'} />
          </div>
          </Grid>


    



          {/* <Grid item xs={12} md={6} lg={12}>
            <AppWebsiteVisits
              title="Your observations in the current year"
              subheader=""
              chartLabels={[
                'Jan',
                'Feb',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'Sept',
                'Oct',
                'Nov',
                'Dec',
              ]}
              chartData={[
                // {
                //   name: 'Richness',
                //   type: 'column',
                //   fill: 'solid',
                //   data: summary.monthlySpecies,
                // },
                {
                  name: 'Individuals',
                  type: 'area',
                  fill: 'gradient',
                  data: summary.monthlyRecords,
                },
                {
                  name: 'Species',
                  type: 'line',
                  fill: 'solid',
                  data: summary.monthlySpecies,
                },
              ]}
            />
          </Grid> */}

          {/* {
            selectedForms.length === 0 && (
              <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Records by protocol"
              chartData={[
                { label: 'Line Transect', value: lineTransect.length },
                { label: 'Territory Mapping', value: territoryMapping.length },
                { label: 'Point Transect', value: pointTransect.length },
                { label: 'Point Count', value: pointCount.length },
                { label: 'Ringing', value: ringing.length },
                { label: 'Others', value: others.length },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>
            )
          } */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={12}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          
          
        </Grid>

        <Checklists />
       { isLoading? <Progress />  : <FirebaseForms  setIsLoading={setIsLoading}/>}
      </Container>
    </Page>
    </>
  )}
    </>
  );
}
